$primaryMain: #1E4A91;
$primaryLight: #EBF3FF;
$primaryLighter: #F1F7FF;
$primaryLightest: #F9FAFE;

$primaryDarker: #0C1E3A;
$secondaryPink: #D41367;
$secondaryPinkLight: #E571A4;
$secondaryPinkLighter: #F3BFD6;
$secondaryPinkDarker: #6A0A34;

$accentRed: #FF4D4F;
$accentYellow: #FFDE69;
$accentOrange: #FF9700;
$accentGreen: #64C635;
$accentBlue: #1890FF;
$accentPurple: #A93495;
$accentRedLight: #FFF1F1;
$accentGreenLight: #F3FBEF;

$neutralMain: #262626;
$neutralDarker: #8C8C8C;
$neutralLighter: #CCCCCC;
$neutralSubtle: #EAEAEA;
$neutralSubtler: #FAFAFA;
$neutralWhite: #FFFFFF;