@keyframes offset {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes rippleAnimation {
  0% {
    transform: scale(0);
    opacity: 0.3;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes logoBounceAnimation {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(0.9);
  }

  40% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes logoRotateAnimation {
  // go back a little
  // go forward a lot and overshoots a bit
  // go back a bit before initial position
  // go forward a bit to initial position
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(-10deg);
  }

  80% {
    transform: rotate(380deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes logoBounceRotateAnimation {
  0% {
    transform: scale(1) rotate(0deg);
  }

  20% {
    transform: scale(1.2) rotate(0deg);
  }

  40% {
    transform: scale(0) rotate(720deg); // middle point
  }

  60% {
    transform: scale(0) rotate(720deg); // middle point
  }

  80% {
    transform: scale(1.2) rotate(1440deg);
  }

  100% {
    transform: scale(1) rotate(1440deg);
  }
}

.svg-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.svgElement {
  margin: 0 auto;
  display: block;
}
