@import '../variables.scss';

.auth-layout {
  padding-top: 160px;
  background-color: $primaryLight;

  .outer-wrapper {
    display: flex;
    justify-content: center;
    .inner-wrapper {
      display: flex;
      max-width: 416px;
      width: 100%;
      justify-content: center;
    }
  }
}