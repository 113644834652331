@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

@font-face {
  font-family: 'Lato Semibold';
  src: url("./fonts/Lato-Semibold.ttf") format("truetype");
}
/* typography */
* {
  font-family: "Lato";
}

/* disable scroll bounce */
html, body {
  height: 100%;
  overflow: hidden;
}

.app {
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &::-webkit-scrollbar {
    display: none !important;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ant-pagination-options {
  text-transform: capitalize;
}