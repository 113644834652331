	/*
  	Flaticon icon font: Flaticon
  	Creation date: 07/12/2020 04:40
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
  margin-left: 20px;
}

.flaticon-salesman:before { content: "\f100"; }
.flaticon-ship:before { content: "\f101"; }
.flaticon-sword:before { content: "\f102"; }
.flaticon-planet-earth:before { content: "\f103"; }
.flaticon-down-arrow:before { content: "\f104"; }
.flaticon-view:before { content: "\f105"; }
.flaticon-hide:before { content: "\f106"; }
.flaticon-add-round-button:before { content: "\f107"; }
.flaticon-trash:before { content: "\f108"; }
.flaticon-remove-circular-button:before { content: "\f109"; }
.flaticon-sort-alphabet-ascending:before { content: "\f10a"; }
.flaticon-search:before { content: "\f10b"; }
.flaticon-tools-and-utensils:before { content: "\f10c"; }
.flaticon-back:before { content: "\f10d"; }
.flaticon-ui:before { content: "\f10e"; }
.flaticon-cancel:before { content: "\f10f"; }
.flaticon-list:before { content: "\f110"; }
.flaticon-focus:before { content: "\f111"; }
.flaticon-plus:before { content: "\f112"; }
.flaticon-minus:before { content: "\f113"; }
.flaticon-information:before { content: "\f114"; }
.flaticon-menu:before { content: "\f115"; }
.flaticon-helm:before { content: "\f116"; }
.flaticon-way:before { content: "\f117"; }
.flaticon-alert:before { content: "\f118"; }
.flaticon-text-document:before { content: "\f119"; }
.flaticon-sync:before { content: "\f11a"; }
.flaticon-directions:before { content: "\f11b"; }
.flaticon-close:before { content: "\f11c"; }
.flaticon-up-chevron:before { content: "\f11d"; }
.flaticon-exclamation-sign:before { content: "\f11e"; }
.flaticon-clock:before { content: "\f11f"; }
.flaticon-padlock:before { content: "\f120"; }
.flaticon-bell:before { content: "\f121"; }
.flaticon-question:before { content: "\f122"; }
.flaticon-euro:before { content: "\f123"; }
.flaticon-handshake:before { content: "\f124"; }
.flaticon-lock:before { content: "\f125"; }
.flaticon-history:before { content: "\f126"; }