@import "../variables.scss";

.main {
  display: grid;
  padding: 0;
  grid-template-columns: 144px auto 104px;
  column-gap: 16px;
  background-color: $primaryMain;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
}

.mainDark{
  background-color: #6A0A34;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primaryDarker;
}

.logoDark{
  background-color: #2A0415;
}

.headerDark{
  background-color: #6A0A34;
}

.headerMenu {
  font-size: 14px;
  line-height: 22px;

  .menuTitleWrapper {
    display: flex;

    .menuTitle {
      font-family: "Lato Semibold";
      margin-right: 4px;
    }
  }

  :global {
    .ant-menu-item-active,
    .ant-menu-submenu-active {
      color: $secondaryPinkLighter !important;
    }
    .ant-menu-submenu-open,
    .ant-menu-submenu-selected {
      color: $secondaryPinkLighter !important;
    }
    .ant-menu-title-content,
    .ant-menu-title-content a {
      font-family: "Lato Semibold" !important;
    }
    .ant-menu-submenu-selected .ant-menu-title-content {
      color: $secondaryPinkLighter !important;
      font-family: "Lato Semibold" !important;
    }
    .ant-menu-submenu-title {
      color: $neutralWhite !important;
    }
    .ant-menu-submenu-title:hover {
      color: $secondaryPinkLighter !important;
    }
    .ant-menu-item,
    .ant-menu-submenu {
      padding-inline: 24px;
      // margin-inline: 24px;
      color: $neutralWhite;
    }
  }
}

.accountMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
  transform: translateX(-180px);

  .createVoyageHeaderButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    transform: translateX(-30px);
  }

  .acquisitionModeDiv{
    height: 40px;
    width: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: $neutralWhite;
    color: #D41367;
    padding: 10px;
    border-radius: 2px;
    transform: translateX(-30px);
  }

  img {
    transform: translateY(7px) translateX(-10px);
    cursor: pointer;
  }
}

.accountMenuOverlay {
  :global {
    .ant-dropdown-menu {
      padding: 0;
      background-color: $neutralWhite;
      .ant-dropdown-menu-item {
        color: $neutralMain;
        padding: 9px 24px;
        .ant-dropdown-menu-title-content > a {
          line-height: 22px;
          font-family: "Lato Semibold" !important;
        }
        &:hover {
          background-color: transparent;
          color: $secondaryPink;
        }
      }
    }
  }
  .signOutBtn {
    padding: 0;
    > span {
      line-height: 22px;
      font-family: "Lato Semibold" !important;
      &:hover {
        color: $secondaryPink;
      }
    }
  }
}

.acquisitionWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  width: 210px;
  height: 150px;
  padding: 10px;

  .switchWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .signout {
    padding: 0px;
    background: none;
    border: none;
    color: $accentBlue;
    cursor: pointer;
  }
}
